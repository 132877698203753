
import { defineComponent } from "vue";

import style from "./style.module.less";

export default defineComponent({
  name: "Articles",
  setup() {
    return () => (
      <div class={style.container}>
        <div class={style.filters}></div>
        <div class={style.main}>
          <div class={style.list}>
            {[1, 2, 3, 4, 5, 6].map((x) => (
              <div class={style.item}>
                <div class={style.header}>
                  <div class={style.tags}>
                    <div>6人剧本</div>
                    <div>古装</div>
                  </div>
                </div>
                <div class={style.body}>
                  <div class={style.title}>末日</div>
                  <div class={style.subtitle}>作者：陆筱婉</div>
                  <div class={style.desc}>
                    2046年，陨石撞地球导致全球火山喷发，火山灰覆盖全球，陨石带来的未知病毒伴随降雨席卷全球。若干年后，人类终于迎来转机，丧尸疫苗的出现终于遏制了丧尸数量的疯狂上涨，人造太阳也终于被研发，但谁是那个拯救人类的英雄……
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class={style.pagination}></div>
        </div>
      </div>
    );
  },
});
